import { enableProdMode, importProvidersFrom, isDevMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { RouteReuseStrategy, provideRouter } from '@angular/router';
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';

import { routes } from './app/app.routes';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { AngularFireModule } from '@angular/fire/compat';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { provideServiceWorker } from '@angular/service-worker';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    provideIonicAngular(),
    provideRouter(routes), importProvidersFrom(provideFirebaseApp(() => initializeApp({ "projectId": "weedies-ab936", "appId": "1:24265858231:web:db9474f2a716e0454c4132", "databaseURL": "https://weedies-ab936-default-rtdb.firebaseio.com", "storageBucket": "weedies-ab936.appspot.com", "apiKey": "AIzaSyC0pOxrrcqsNjrUQAc5yppZWB0TYSzVflM", "authDomain": "weedies-ab936.firebaseapp.com", "messagingSenderId": "24265858231", "measurementId": "G-XH604CHWRQ" }))), importProvidersFrom(provideAuth(() => getAuth())),
    AngularFireModule,
    provideServiceWorker('ngsw-worker.js', {
        enabled: !isDevMode(),
        registrationStrategy: 'registerWhenStable:30000'
    }), importProvidersFrom(provideFirebaseApp(() => initializeApp({ "projectId": "weedies-ab936", "appId": "1:24265858231:web:db9474f2a716e0454c4132", "databaseURL": "https://weedies-ab936-default-rtdb.firebaseio.com", "storageBucket": "weedies-ab936.appspot.com", "apiKey": "AIzaSyC0pOxrrcqsNjrUQAc5yppZWB0TYSzVflM", "authDomain": "weedies-ab936.firebaseapp.com", "messagingSenderId": "24265858231", "measurementId": "G-XH604CHWRQ" }))), importProvidersFrom(provideAuth(() => getAuth())),
    provideServiceWorker('ngsw-worker.js', {
        enabled: !isDevMode(),
        registrationStrategy: 'registerWhenStable:30000'
    })
],
});
